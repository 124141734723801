import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ChangePassword from "../pages/ChangePassword";

const AuthLayout = lazy(() => import("../components/Layouts/AuthLayout"));
const MainLayout = lazy(() => import("../components/Layouts/MainLayout"));
const PublicLayout = lazy(() => import("../components/Layouts/PublicLayout"));

const AuthGuard = lazy(() => import("../components/RouterGuards/AuthGuard"));
const AppLoader = lazy(() => import("../components/AppLoader"));

const Home = lazy(() => import("../pages/Home"));
const Family = lazy(() => import("../pages/Family"))
const MyReports = lazy(() => import("../pages/Reports"));
const MyProfile = lazy(() => import("../pages/MyProfile"));
const SignIn = lazy(() => import("../pages/SignIn"));
const SignUp = lazy(() => import("../pages/SignUp"));
const PageNotFound = lazy(() => import("../pages/404"));
const MedicationReport = lazy(() => import("../pages/MedicationReport"));
// const = lazy(() => import(HeartReports"../pages/HeartReports))"
// const = lazy(() => import(PolygenicRiskScore"../pages/PolygenicRiskScore"));
// const = lazy(() => import(PolygenicRiskScoreDetail"../pages/PolygenicRiskScoreDetail"));
// const = lazy(() => import(PolygenicDiseaseDetail"../pages/PolygenicDiseaseDetail"));
// const = lazy(() => import(PolygenicRareVariant"../pages/PolygenicRareVariant"));
// const = lazy(() => import(PolygenicVariantsMonitor"../pages/PolygenicVariantsMonitor"));
const FamilyMemberDetail = lazy(() => import("../pages/FamilyMemberDetail"));
const Concierge = lazy(() => import("../pages/Concierge"));
const ReportPage = lazy(() => import("../pages/ReportPage"));
const ReportDetailPage = lazy(() => import("../pages/ReportDetailPage"));
// const = lazy(() => import(NutritionReport"../pages/NutritionReport"));
const NutritionReport = lazy(() => import("../pages/NutritionReport2"));
const NutritionCategory = lazy(() => import("../pages/NutritionCategory"));
const NutritionReportSupplement = lazy(() => import("../pages/NutritionReportSupplement"));
const NutritionSingleFood = lazy(() => import("../pages/NutritionSingleFood"));
const NutritionFoodCategory = lazy(() => import("../pages/NutritionFoodCategory"));
const Library = lazy(() => import("../pages/Library"));
const Faq = lazy(() => import("../pages/Faq"));
const HowItWorks = lazy(() => import("../pages/HowItWorks"));
const Article = lazy(() => import("../pages/Article"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));

export default function RootRoutes() {
  const userRedirect = (nextState, replace) => {
    var redirectPath = nextState.location.pathname
    replace({
      pathname: redirectPath,
    })
  };

  return (
    <Router>
      <Suspense fallback={<AppLoader />}>
        <Routes>
          <Route path="/" element={
            <AuthGuard>
              <MainLayout>
                <Home />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/family" element={
            <AuthGuard>
              <MainLayout>
                <Family />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/reports" element={
            <AuthGuard>
              <MainLayout>
                <MyReports />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/report/:report_name" element={
            <AuthGuard>
              <MainLayout>
                <ReportPage />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/report/:report_name/:detail_id" element={
            <AuthGuard>
              <MainLayout>
                <ReportDetailPage />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/medication-report" element={
            <AuthGuard>
              <MainLayout>
                <MedicationReport />
              </MainLayout>
            </AuthGuard>
          } />
          {/* <Route path="/heart-reports" element={
            <AuthGuard>
              <MainLayout>
                <HeartReports />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/heart-reports/prc" element={
            <AuthGuard>
              <MainLayout>
                <PolygenicRiskScore />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/heart-reports/prc-detail" element={
            <AuthGuard></AuthGuard>
              <MainLayout>
                <PolygenicRiskScoreDetail />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/heart-reports/rare-variants" element={
            <AuthGuard>
              <MainLayout>
                <PolygenicRareVariant />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/heart-reports/variants-monitor" element={
            <AuthGuard>
              <MainLayout>
                <PolygenicVariantsMonitor />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/heart-reports/rare-variants/:id" element={
            <AuthGuard>
              <MainLayout>
                <PolygenicDiseaseDetail />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/heart-reports/variants-monitor/:id" element={
            <AuthGuard>
              <MainLayout>
                <PolygenicDiseaseDetail />
              </MainLayout>
            </AuthGuard>
          } /> */}
          <Route path="/nutrition-report" element={
            <AuthGuard>
              <MainLayout>
                <NutritionReport />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/nutrition-category/:type/:filterKey" element={
            <AuthGuard>
              <MainLayout>
                <NutritionCategory />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/nutrition-supplement/:substance" element={
            <AuthGuard>
              <MainLayout>
                <NutritionReportSupplement />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/nutrition-single-foods/:name" element={
            <AuthGuard>
              <MainLayout>
                <NutritionSingleFood />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/nutrition-food-category/:food" element={
            <AuthGuard>
              <MainLayout>
                <NutritionFoodCategory />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/concierge" element={
            <AuthGuard>
              <MainLayout>
                <Concierge />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/library" element={
            <AuthGuard>
              <MainLayout>
                <Library />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/faq" element={
            <AuthGuard>
              <MainLayout>
                <Faq />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/how-it-works" element={
            <AuthGuard>
              <MainLayout>
                <HowItWorks />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/article/:id" element={
            <AuthGuard>
              <MainLayout>
                <Article />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/profile" element={
            <AuthGuard>
              <MainLayout>
                <MyProfile />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/profile/:id" element={
            <AuthGuard>
              <MainLayout>
                <FamilyMemberDetail />
              </MainLayout>
            </AuthGuard>
          } />
          <Route path="/signup" element={
            <AuthLayout>
              <SignUp />
            </AuthLayout>
            }
          />
          <Route path="/login" element={
            <AuthLayout>
              <SignIn />
            </AuthLayout>
            } 
          />
          <Route path="/reset-password" element={
            <AuthLayout>
              <ResetPassword />
            </AuthLayout>
            } 
          />
          <Route path="/change-password" element={
            <AuthGuard>
              <MainLayout>
                <ChangePassword />
              </MainLayout>
            </AuthGuard>
          } />
          <Route
            path='/*'
            onEnter={userRedirect}
            element={
              <PublicLayout>
                <PageNotFound />
              </PublicLayout>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}